import React from 'react';
import Helmet from 'react-helmet';
import { Link, graphql } from 'gatsby';
import Layout from '../components/layout';

class Archives extends React.Component {
  render() {
    const markdownList = this.props.data.md.edges;
    const oldArticleList = this.props.data.wp.edges;
    return (
      <Layout>
        <Helmet>
          <title>Articles</title>
          {/* <meta name="description" content={siteDescription} /> */}
        </Helmet>

        <div id="main">
          <section id="one">
            <h1>Articles</h1>
            {markdownList.map((item, key) => (
              <React.Fragment key={key}>
                <p>
                  <Link to={item.node.frontmatter.path}>
                    {item.node.frontmatter.title}
                  </Link>{' '}
                  <br />
                  {item.node.frontmatter.date}
                </p>
              </React.Fragment>
            ))}
          </section>
          <section id="two">
            <h2>Really Old Archives</h2>
            <p>These date back to my original site 10 years ago.</p>
            {oldArticleList.map((item, key) => (
              <React.Fragment key={key}>
                <Link to={`/2009/my-posts/${item.node.slug}`}>
                  <span
                    dangerouslySetInnerHTML={{ __html: item.node.title }}
                  ></span>
                </Link>
                <br />
              </React.Fragment>
            ))}
          </section>
        </div>
      </Layout>
    );
  }
}

export default Archives;

export const pageQuery = graphql`
  query {
    wp: allWordpressPost(sort: { fields: [date], order: DESC }) {
      edges {
        node {
          title
          excerpt
          slug
          date(formatString: "DD MMMM YYYY")
        }
      }
    }
    md: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 1000
    ) {
      edges {
        node {
          frontmatter {
            path
            date(formatString: "DD MMMM YYYY")
            title
          }
        }
      }
    }
  }
`;
